<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md12>
          <v-col cols="12">
            <v-card outlined elevation-0 >
              <div>
                <v-container fluid class="pb-0">
                  <v-row align="center">

                      <v-col md="12">
                        <h2>{{ getPlatformName(getPlatformId) }}</h2>
                      </v-col>

                      <v-col md="2">
                        <v-dialog ref="dialog" v-model="dialogFromTime" width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense v-model="filterFromTime" :label="$t('generic.from')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                          </template>
                          <v-date-picker v-model="filterFromTime" scrollable @input="dialogFromTime = false"></v-date-picker>
                        </v-dialog>
                      </v-col>

                      <v-col md="2">
                        <v-dialog ref="dialog" v-model="dialogToTime" width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense v-model="filterToTime" :label="$t('generic.to')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                          </template>
                          <v-date-picker v-model="filterToTime" scrollable @input="dialogToTime = false"></v-date-picker>
                        </v-dialog>
                      </v-col>

<!--                      <v-col md="2" >-->
<!--                        <span v-if="enableTimezone" class="title-table">{{ (this.skinTimezone == '') ? $t('noTimezoneSkin') : this.skinTimezone}}</span>-->
<!--                        <v-checkbox-->
<!--                                class="no-padding no-margin"-->
<!--                                v-model="enableTimezone"-->
<!--                                :label="$t('enableTimezone')"-->
<!--                                @change="checkTimezone"-->
<!--                        ></v-checkbox>-->
<!--                      </v-col>-->

                      <v-col class="d-flex" cols="12" md="3">
                        <v-select class="text-capitalize" :items="orderBy" item-text="text" item-value="value" label="Order By" dense outlined v-model="filterType"></v-select>
                      </v-col>

                    <v-col class="d-flex" cols="12" md="" style="margin-top: -20px; justify-content: flex-end;">
                      <v-btn depressed color="primary " @click="resetFilter" class="mr-5">{{ $t('generic.reset') }}</v-btn>
                      <v-btn depressed color="secondary" @click="searchBets" @keyup.enter="searchBets" class="mr-5">{{ $t('generic.search') }}</v-btn>
                      <v-btn  v-if="getPlatformId == 0" depressed color="secondary" @click="sendBookie" @keyup.enter="sendBookie" class="mr-5">{{ $t('sendbookie') }}</v-btn>
                    </v-col>

                  </v-row>
                </v-container>
              </div>
            </v-card>
            <v-card outlined elevation-0 class="mt-1">
                <v-data-table :headers="headers" :items="getBetReport" class="elevation-1 head-capitalize" :loading="getPreload" disable-pagination hide-default-footer>

                    <template v-slot:item.programming="{ item }">
                        <span class="font-weight-bold">{{ item.sport_name }} / {{ item.category_name }} / {{ item.tournament_name }}</span>
                    </template>

                    <template v-slot:item.quickbet="{ item }">
                        <span>{{ item.quickbet }}</span>
                    </template>

                    <template v-slot:item.event="{ item }">
                        <span><small>({{ item.event_source }})</small></span><br />
                        <span class="font-weight-bold">{{ item.event_description }}</span>
                    </template>

                    <template v-slot:item.sign="{ item }">
                        <span class="text-center"><small>({{ item.market_name }})</small></span><br />
                        <span class="text-center">{{ item.outcome_name }}</span> <span v-if="item.specifer">{{ item.specifer }}</span> <span v-if="item.spec">{{ item.spec }}</span>
                    </template>

<!--                    <template v-slot:item.status="{ item }">-->
<!--                        <span>{{ item.data.status_event }}</span>-->
<!--                    </template>-->

                    <template v-slot:item.status="{ item }" style="font-size: 10px">
<!--                        <span v-if="item.progress > 0" class="list-pending">-->
<!--                          <div class="circle-pending"></div>-->
<!--                        </span>-->
                        <span v-if="item.result == false" class="red--text">
                          <v-icon small color="red">mdi-close-circle</v-icon>
                        </span>
                        <span v-if="item.result == true" class="green--text">
                          <v-icon small color="green">mdi-checkbox-marked-circle</v-icon>
                        </span>
                    </template>

                    <template v-slot:item.total="{ item }">
                        <span>{{ item.count }}</span>
                    </template>

                    <template v-slot:item.amount="{ item }">
                        <span class="green--text">{{ parseFloat(item.amount).toFixed(2) }}</span>
                    </template>

                    <template v-slot:item.won="{ item }">
                        <span class="red--text">{{ parseFloat(item.won).toFixed(2) }}</span>
                    </template>

                    <template v-slot:item.lose="{ item }">
                        <span  class="green--text">{{ parseFloat(item.lose).toFixed(2) }}</span>
                    </template>

                    <template v-slot:item.profit="{ item }">
                        <span class="green--text" v-if="(item.amount - item.won) > 0">{{ parseFloat(item.amount - item.won).toFixed(2) }}</span>
                        <span class="red--text" v-if="(item.amount - item.won) <= 0">{{ parseFloat(item.amount - item.won).toFixed(2) }}</span>
                    </template>

                    <template v-slot:item.progress="{ item }">
                        <span  class="orange--text">{{ parseFloat(item.progress).toFixed(2) }}</span>
                    </template>

<!--                    <template v-slot:item.bet_context="{ item }">-->
<!--        <span v-if="item.bet_context == 'live'" class="context live-label">-->
<!--          {{ $t('bets.'+item.bet_context) }}-->
<!--        </span>-->
<!--                        <span v-else-if="item.bet_context == 'prematch'"  class="context prematch-label">-->
<!--          {{ $t('bets.'+item.bet_context) }}-->
<!--        </span>-->
<!--                        <span v-else-if="item.bet_context == 'mixed'"  class="context mixed-label">-->
<!--          {{ $t('bets.'+item.bet_context) }}-->
<!--        </span>-->
<!--                    </template>-->

                </v-data-table>
            </v-card>
          </v-col>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import _ from 'lodash';
import BetDetail from "@/components/bets/betDetail";

export default {
  name: "betReport",
  data() {
    return {
      snackbar: false,
      snackbar_message: '',
      skinTimezone: '',
      enableTimezone: false,
      dialogFromTime: false,
      dialogToTime: false,
      filterFromTime: "",
      filterToTime: "",
      filterType: "",
      orderBy: [
        {text: this.$t('risk.betReport.number'), value: "number"},
        {text: this.$t('risk.betReport.played'), value: "played"},
        {text: this.$t('risk.betReport.won'), value: "won"},
        {text: this.$t('risk.betReport.profit'), value: "profit"},
        {text: this.$t('risk.betReport.risk'), value: "risk"}
      ],
        headers: [
            {text: this.$t('bets.programming'), value: 'programming', width: '23%', sortable: false},
            {text: this.$t('bets.qbet'), value: 'quickbet', width: "5%", sortable: false},
            {text: this.$t('bets.event'), value: 'event', width: "27%", sortable: false},
            {text: this.$t('bets.sign'), value: 'sign', width: "10%", sortable: false},
            {text: this.$t('bets.status'), value: 'status', width: "5%", sortable: false},
            {text: this.$t('bets.totalBets'), value: 'total', width: "5%", sortable: false},
            {text: this.$t('bets.amount'), value: 'amount', width: "5%", sortable: false},
            {text: this.$t('bets.win'), value: 'won', width: "5%", sortable: false},
            {text: this.$t('bets.lose'), value: 'lose', width: "5%", sortable: false},
            {text: this.$t('bets.profit'), value: 'profit', width: "5%", sortable: false},
            {text: this.$t('bets.progress'), value: 'progress', width: "5%", sortable: false}
        ],
    }
  },
  computed: {
    ...mapGetters({
      'getBetReport': 'bet/getBetReport',
      'getPreload': 'utils/getPreload',
      'getSkinFilters': 'skins/getSkinFilters',
      'getTimezone':'bet/getTimezone',
      'getPlatformId': 'rules/getPlatformId',
    }),
  },

  methods: {
    ...mapActions({
      'fetchBetReport': 'bet/fetchBetReport',
      'setPreload': 'utils/setPreload',
    }),
    checkTimezone(){
      var platform = _.find(this.getList, {'platform_id':this.getPlatformId})
      if(this.enableTimezone){
        if(platform.settings.timezone){
          this.skinTimezone = platform.settings.timezone
          this.setTimezone(platform.settings.timezone)
        }
      }else{
        this.skinTimezone = ''
        this.setTimezone('')
      }
    },
    async showDetail(bet) {
      // await this.setShowDetail(false)
      // await this.setBetDetail(bet)
      // await this.setShowDetail(true)
    },
    getPlatformName(platform_id) {
      var platform = _.find(this.getSkinFilters, {platform_id: platform_id})
      return platform.name
    },
    getWinning(bet) {
      switch (bet.type) {
        case('single'):
        case('multiple'):
          return bet.currencySymbol +' '+parseFloat(bet._multiple_detail.winning_total).toFixed(2)
        case('integral'):
          return bet.currencySymbol +' '+parseFloat(bet._integral_detail.winning_total_max).toFixed(2)
        case('system'):
          return bet.currencySymbol +' '+parseFloat(bet._system_detail.winning_all_total).toFixed(2)
      }
    },
    async resetFilter(){
      this.filterFromTime = ''
      this.filterToTime = ''

      await this.searchBets()

    },
    async searchBets(){
      if(this.getPlatformId > 0){
        this.filterPlatform = this.getPlatformId
      }

      let criteria = {
        ...(this.filterPlatform !== '') && {platform_id: +this.filterPlatform},
        ...(this.filterType !== '') && {type: this.filterType},
        ...(this.filterFromTime !== '') && {fromTime: new Date(this.filterFromTime).getTime()},
        ...(this.filterToTime !== '') && {toTime: new Date(this.filterToTime).getTime()+86399000},
      }

      await this.setPreload(true);
      await this.fetchBetReport(criteria)
      await this.setPreload(false);

    }
  }
}
</script>

<style>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
</style>
