import axios from "axios";
import CryptoJS from 'crypto-js';
import moment from "moment-timezone";
// const sound = require("sound-play");

const state = {
    options:{
        page: 1,
        limit: 10
    },
    criteria:{},
    list: [],
    list_total: [],
    list_reserve: [],
    list_report: [],
    counts: 0,
    counts_reserve: 0,
    showDetail: false,
    betDetail: {},
    timezone: '',
    reviewCount: 0,
    reviewNotification: false,
    reviewStart: true,
    bets_all: [],
    firstSearchBet: true,
    countBetInReview: 0
};

const getters = {
    getBets(state, getters) {
        return state.list;
    },
    getBetsTotals(state, getters) {
        return state.list_total;
    },
    getBetDetail(state, getters) {
        return state.betDetail;
    },
    getShowDetail(state, getters) {
        return state.showDetail;
    },
    getOptions(state, getters) {
        return state.options;
    },
    getCounts(state, getters) {
        return state.counts;
    },
    getCriteria(state, getters) {
        return state.criteria;
    },
    getTimezone(state, getters) {
        return state.timezone;
    },
    getReviewCount(state, getters) {
        return state.reviewCount;
    },
    getReviewNotification(state, getters) {
        return state.reviewNotification;
    },
    getReserve(state, getters) {
        return state.list_reserve;
    },
    getBetReport(state, getters) {
        return state.list_report;
    },
    getCountsReserve(state, getters) {
        return state.counts_reserve;
    },
    getMyBetsAll(state) {
        return state.bets_all
    },
    getCountBetInReview(state){
        return state.countBetInReview
    }
};

const actions = {

    async fetchBets(context, payload) {

        let url = `${context.rootGetters['settings/getBetApiUrl']}bet/get-list-paginate`;
        if(this.firstSearchBet){
            payload.fromTime = moment().subtract(1, 'month').format('YYYY-MM-DD');
            payload.toTime = moment().format('YYYY-MM-DD');
            this.firstSearchBet = false
        }
        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('setBets', res.data.data);
                if(res.data.sum.length > 0){
                    context.commit('setBetsTotals', res.data.sum[0]);
                }else {
                    context.commit('setBetsTotals', {bets: res.data.counts, amount: 0, winning: 0});
                }

                context.commit('SET_COUNTS', res.data.counts);

            }).catch((error) => {
                console.log(error);
            });
    },
    async fetchBetsReserve(context, payload) {
        let myAudio = new Audio("https://sirplay-amazon.s3.eu-west-3.amazonaws.com/positive.wav");
        let url = `${context.rootGetters['settings/getUrl']}bet/get-list-reserve`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                if(res.data.data.length > 0){

                    if(state.list_reserve.length > res.data.data.length){
                        let remove_bets = _.differenceBy(state.list_reserve, res.data.data, 'code');
                        remove_bets.forEach(bet => {
                            state.list_reserve = state.list_reserve.filter(function(el){
                                return el.code !== bet.code;
                            });
                        });

                    }else{
                        let new_bets = _.differenceBy(res.data.data, state.list_reserve, 'code');
                        new_bets.forEach(bet => {


                            // get state for this user of platform
                            console.log('*************************************************************');
                            console.log('[DEBUG] new bet to check');
                            console.log('*************************************************************');
                            const idUser = bet.user_id;
                            const platformId = bet.platform_id;

                            const payloadUserCheck = {
                                idUser: idUser,
                                platformId: platformId
                            }

                            context.dispatch('users/isUserBlocked', payloadUserCheck, {root:true}) // async

                            //fine get state for this user of platform

                            // console.log('amount_real inizio: ', bet.amount_real);
                            // console.log('odds inizio: ', JSON.stringify(bet.odds));

                            bet.hash_unique = Buffer.from(bet.amount_real + JSON.stringify(bet.odds)).toString('base64');
                            state.list_reserve.push(bet);
                            myAudio.play();
                        });
                    }
                    context.commit('SET_COUNT_BET_IN_REVIEW', state.list_reserve.length);

                }else{
                    state.list_reserve = [];
                    context.commit('SET_COUNTS_RESERVE', 0);
                    context.commit('SET_COUNT_BET_IN_REVIEW', state.list_reserve.length);
                }

                // myAudio.pause();
                // myAudio.currentTime = 0;

            }).catch((error) => {
                console.log(error);
            });
    },
    async sendBookie(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/get-list-nosent`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return true
                //context.commit('setBets', res.data.data);
                //context.commit('SET_COUNTS', res.data.counts);

            }).catch((error) => {
                console.log(error);
            });
    },


    async fetchBetDetail(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/bet-detail`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                if(res.data.data){
                    context.dispatch('setBetDetail', res.data.data)
                }

            }).catch((error) => {
                console.log(error);
            });
    },

    async defineBetsFromEvents(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/define-bet-from-event`;

        return await axios.post(`${url}`,
            {event_id: payload.event_id},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                // context.commit('setBets', res.data.data);

            }).catch((error) => {
                console.log(error);
            });
    },

    async defineOddInBet(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/v2/define-odd-in-bet`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                if(res.data.updated){
                    return true
                }else{
                    return false
                }
                // context.commit('setBets', res.data.data);

            }).catch((error) => {
                console.log(error);
            });
    },

    async defineOdd(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/v2/define-odd`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                if(res.data.updated){
                    return true
                }else{
                    return false
                }
                // context.commit('setBets', res.data.data);

            }).catch((error) => {
                console.log(error);
            });
    },


    async sendResultToPlatform(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}cart/send-defined-bet-bookie`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return res.data

            }).catch((error) => {
                console.log(error);
            });
    },

    async defineBet(context, payload) {

        // let url = `${context.rootGetters['settings/getUrl']}bet/auto-define-bet`;
        let url = `${context.rootGetters['settings/getUrl']}bet/v2/define-bet`;

        return await axios.post(`${url}`,
            {code: payload.code},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                return res
                // context.commit('setBets', res.data.data);

            }).catch((error) => {
                console.log(error);
            });
    },

    async sendReviewBet(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}cart/review-bet`;

        return await axios.post(`${url}`,
            {code: payload.code, mode: payload.mode},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res

            }).catch((error) => {
                console.log(error);
            });
    },

    async sendAcceptBet(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}cart/v2/review-bet/accept`;

        return await axios.post(`${url}`,
            {code: payload.code},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res

            }).catch((error) => {
                console.log(error);
            });
    },

    async sendRejectBet(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}cart/v2/review-bet/reject`;

        return await axios.post(`${url}`,
            {code: payload.code},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {
                return res

            }).catch((error) => {
                console.log(error);
            });
    },

    async defineVoid(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/v2/make-bet-void`;

        return await axios.post(`${url}`,
            {code: payload.code},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

    },
    async defineCancel(context, payload){

        let url = `${context.rootGetters['settings/getUrl']}bet/v2/make-bet-cancelled`;

        return await axios.post(`${url}`,
            {code: payload.code},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})

    },

    async requestRollback(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}cart/v2/rollback`;

        return await axios.post(`${url}`,
            {code: payload.code, manual: payload.manual},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                // context.commit('setBets', res.data.data);

            }).catch((error) => {
                console.log(error);
            });
    },

    async recoveryBetSettlement(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/recovery-bet-settlement`;

        // ToDo Different provider
        var ls = '6063042d64ecd8adc0cfb242'

        return await axios.post(`${url}`,
            {event_id: payload.event_id, provider: ls},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                // context.commit('setBets', res.data.data);

            }).catch((error) => {
                console.log(error);
            });
    },

    async setBetDetail(context, payload){
        var betObject = {...payload}

        delete betObject.__v

        switch(payload.type){
            case('single'):
            case('multiple'):
                betObject.detail = payload._multiple_detail;
                delete betObject._multiple_detail
                break
            case('integral'):
                betObject.detail = payload._integral_detail;
                delete betObject._integral_detail
                break
            case('system'):
                betObject.detail = payload._system_detail;
                delete betObject._system_detail
                break
        }
        context.commit("SET_BET_DETAIL",betObject)
    },

    async fetchReviewCounts (context) {

        let url = `${context.rootGetters['settings/getUrl']}bet/review-counts`;

        return await axios.post(`${url}`,
            {},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('SET_REVIEW_COUNTS',res.data.data);

            }).catch((error) => {
                console.log(error);
            });

    },

    async fetchMyBetsAll(context, payload){

        var all = (payload.all != undefined && payload.all != undefined) ? payload.all : false;
        let user_id = (payload.user_id != undefined && payload.user_id != undefined) ? payload.user_id : false;
        let platform_id = (payload.platform_id != undefined && payload.platform_id != undefined) ? payload.platform_id : false;
        let in_progress = (payload.in_progress != undefined && payload.in_progress != undefined) ? payload.in_progress : false;

        let url = `${context.rootGetters['settings/getBetApiUrl']}bet/get-my-bets-reserve`;
        return  await axios.post(`${url}`, {'id_user':user_id, 'all': all, platform_id: platform_id, in_progress: in_progress},{'headers': { 'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`} })
            .then(async (res) =>{

                context.commit('SET_MY_BETS_ALL', res.data.data);

            }).catch((error) => {
                console.log(error);
            });

    },

    async placeBet(context, payload){
        let url = `${context.rootGetters['settings/getBetApiUrl']}cart/place-changed-bet`;

        await axios.post(`${url}`, payload, {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then(async (res) => {
                if(res.data.result && res.data.result == true){

                    let payload_request = {
                        "platform_id": payload.platform_id,
                        "code": payload.parentBet
                    }

                    let url = `${context.rootGetters['settings/getUrl']}bet/remove-bet-reserve`;

                    return await axios.post(`${url}`,
                        {...payload_request},
                        {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
                        .then((res) => {

                            context.commit('removeBetPLaced', payload.parentBet);

                        }).catch((error) => {
                            console.log(error);
                        });
                }
            }).catch((error) => {
                console.log(error);
            });
    },

    async rejectBet(context, payload){

        let payload_request = {
            "platform_id": payload.platform_id,
            "code": payload.code
        }

        let url = `${context.rootGetters['settings/getUrl']}bet/remove-bet-reserve`;

        return await axios.post(`${url}`,
            {...payload_request},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('removeBetPLaced', payload.code);

            }).catch((error) => {
                console.log(error);
            });
    },

    async saveLogsReview(context, payload){

        let payload_request = {
            "code": payload.code,
            "betCode": payload.betCode,
            "login": payload.login,
            "action": payload.action,
            "old_value": payload.old_value,
            "new_value": payload.new_value
        }

        let url = process.env.VUE_APP_VALIDATE_URL_API + 'users/log/' + payload.platform_id + '/' + payload.user_id;

        return await axios.post(`${url}`,
            {...payload_request},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                console.log('log user action');

            }).catch((error) => {
                console.log(error);
            });
    },

    async fetchBetReport(context, payload) {

        let url = `${context.rootGetters['settings/getUrl']}bet/report`;

        return await axios.post(`${url}`,
            {...payload},
            {'headers': {'Authorization': `Bearer ${context.rootGetters['profile/getToken']}`}})
            .then((res) => {

                context.commit('setBetReport', res.data.data);

            }).catch((error) => {
                console.log(error);
            });
    },

    async removeBetDetail(context, payload){
        context.commit("SET_BET_DETAIL",{})
    },

    async setShowDetail(context, payload){
        context.commit("SET_SHOW_DETAIL",payload)
    },

    async setOptions(context, payload){
        context.commit("SET_OPTIONS",payload)
    },

    async setCounts(context, payload){
        context.commit("SET_COUNTS",payload)
    },

    async setCountsReserve(context, payload){
        context.commit("SET_COUNTS_RESERVE",payload)
    },

    async setCriteria(context, payload){
        context.commit("SET_CRITERIA",payload)
    },

    async setTimezone(context, payload){
        context.commit("SET_TIMEZONE",payload)
    },

    async setReviewNotification(context, payload){
        context.commit("SET_REVIEW_NOTIFICATION",payload)
    },

    async updateUserNoteForBetInAcceptance(context, payload){
        context.commit("UPDATE_USERS_NOTE_FOR_BET_IN_ACCEPTANCE", payload);
    },

    async updateAgencyNoteForBetInAcceptance(context, payload) {
        context.commit("UPDATE_AGENCIES_NOTE_FOR_BET_IN_ACCEPTANCE", payload);
    }


};

const mutations = {
    setBets(state, payload) {
        state.list = payload;
    },
    setBetsTotals(state, payload) {
        state.list_total = payload;
    },
    setBetsReserve(state, payload) {
        state.list_reserve = payload;
    },
    setBetReport(state, payload) {
        state.list_report = payload;
    },
    SET_BET_DETAIL(state, payload) {
        state.betDetail = payload;
    },
    SET_SHOW_DETAIL(state, payload) {
        state.showDetail = payload;
    },
    SET_OPTIONS(state, payload) {
        state.options = payload;
    },
    SET_COUNTS(state, payload) {
        state.counts = payload;
    },
    SET_COUNTS_RESERVE(state, payload) {
        state.counts_reserve = payload;
    },
    SET_CRITERIA(state, payload) {
        state.criteria = payload;
    },
    SET_TIMEZONE(state, payload) {
        state.timezone = payload;
    },
    SET_REVIEW_COUNTS(state, payload) {
        if(payload > state.reviewCount && state.reviewStart == false){
            state.reviewNotification = true
        }else{
            state.reviewStart = false
        }
        state.reviewCount = payload;
    },
    SET_REVIEW_NOTIFICATION(state, payload) {
        state.reviewNotification = payload;
    },
    SET_MY_BETS_ALL(state, payload){
        state.bets_all = payload
    },
    removeBetPLaced(state, payload){
        const bets =  state.list_reserve;

        const bets_list = bets.filter(bet => {
            return bet.code != payload
        })

        state.list_reserve = bets_list;
    },
    UPDATE_USERS_NOTE_FOR_BET_IN_ACCEPTANCE(state, payload){

        const messageNote = payload.userNote;
        const idUser = payload.idUser;
        let bets = state.list_reserve;

        state.list_reserve = null;
        bets.filter(bet => bet.user_id == idUser ).map(bet => bet.userNote = messageNote);
        state.list_reserve = bets;
    },

    UPDATE_AGENCIES_NOTE_FOR_BET_IN_ACCEPTANCE(state, payload){
        const messageNote = payload.userNote;
        const idAgency = payload.idAgency;
        let bets = state.list_reserve;

        state.list_reserve = null;
        bets.filter(bet => bet.agencyId.toString() === idAgency.toString()).map(bet => bet.agencyNote = messageNote);
        state.list_reserve = bets;
    },
    SET_COUNT_BET_IN_REVIEW (state, payload){
        state.countBetInReview = payload != null && +payload >= 0 ? payload: 0
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
